import React, { useState } from "react";
import Section from "../../components/section";

import bg2 from "../../images/background-2.png";
import bg3 from "../../images/background-3.png";
import bg4 from "../../images/background-4.png";
import bg5 from "../../images/background-5.png";
import bg6 from "../../images/background-6.png";
import bg7 from "../../images/background-7.png";
import bg8 from "../../images/background-8.png";
import bgD2 from "../../images/background-deco-2.svg";

import {
  MdShortText,
  MdMenuBook,
  MdOutlineBookmark,
  MdSchool,
} from "react-icons/md";
import {
  AiOutlineGroup,
  AiFillClockCircle,
  AiFillFileText,
} from "react-icons/ai";

const bgList = [bg2, bg3, bg4, bg5, bg6, bg7, bg8];

const Modes = () => {
  const [bgId, setBgId] = useState(0);

  return (
    <Section backgrounds={{ lg: [bgList[bgId], bgD2], sm: [bgD2] }} id="modes">
      <div className="flex flex-col justify-center items-center w-full h-full">
        <p className="text-white text-6xl mb-4 text-center">練習模式</p>
        <p className="hidden lg:block text-white text-xl mb-4 text-center">
          將鼠標放到練習模式上，左方會顯示相應的截圖
        </p>
        <div className="grid grid-cols-3 gap-4 text-xl">
          <div
            className="clickable-card col-span-2 row-span-2"
            onMouseEnter={() => setBgId(0)}
          >
            <MdShortText className="text-5xl" />
            <p>
              3000常用文字練習
              <br />
              折碼詳情
            </p>
          </div>
          <div className="clickable-card" onMouseEnter={() => setBgId(1)}>
            <AiFillClockCircle className="text-3xl" />
            <p>限時練習</p>
          </div>
          <div className="clickable-card" onMouseEnter={() => setBgId(2)}>
            <AiOutlineGroup className="text-3xl" />
            <p>部首練習</p>
          </div>

          <div className="clickable-card" onMouseEnter={() => setBgId(3)}>
            <MdOutlineBookmark className="text-3xl" />
            <p>書籤收藏</p>
          </div>
          <div
            className="clickable-card col-span-2"
            onMouseEnter={() => setBgId(4)}
          >
            <AiFillFileText className="text-3xl" />
            <p>文章練習</p>
          </div>
          <div
            className="clickable-card  col-span-2"
            onMouseEnter={() => setBgId(5)}
          >
            <MdSchool className="text-3xl" />
            <p>倉頡教學</p>
          </div>
          <div className="clickable-card" onMouseEnter={() => setBgId(6)}>
            <MdMenuBook className="text-3xl" />
            <p>離線字典</p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Modes;
