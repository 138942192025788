import React, { useEffect, useState } from "react";

const Section = ({
  backgrounds = {lg: [], sm: []},
  children,
  bgFixed = false,
  autoHeight = false,
  id = "",
}) => {
  return (
    <div className="relative">
      <div
        id={id}
        className={`anchor absolute -mt-16 ${
          autoHeight ? "lg:-mt-20" : "lg:mt-0"
        }`}
      />
      {backgrounds.lg.length > 1 ? (
        <div
          className="hidden section w-full bg-gray-200 lg:flex bg-fixed bg-cover bg-left p-8 transition-all duration-500"
          style={{
            backgroundImage: backgrounds.lg.map((bg) => `url(${bg})`).join(","),
          }}
        >
          <div className="w-1/3"></div>
          <div className="w-2/3 p-8">{children}</div>
        </div>
      ) : (
        <div
          className="hidden section w-full bg-gray-200 lg:flex items-center lg:items-stretch bg-bottom bg-cover p-8 transition-all duration-500"
          style={{
            backgroundImage: backgrounds.lg.map((bg) => `url(${bg})`).join(","),
            backgroundAttachment: bgFixed ? "fixed" : "auto",
            minHeight: autoHeight ? "auto" : "100vh",
          }}
        >
          <div className="flex flex-grow w-full p-8">{children}</div>
        </div>
      )}
      <div
        className="section w-full bg-gray-200 lg:hidden bg-cover p-8 flex justify-center items-center"
        style={{
          backgroundImage: backgrounds.sm.map((bg) => `url(${bg})`).join(","),
          minHeight: autoHeight ? "auto" : "100vh",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Section;
